/** @jsx jsx */
import { jsx, Heading } from "theme-ui"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Layout from "./layout"
import SEO from "./seo"
import accentColors from "../../../../accentColors";
require("../../../../accentColors");

type PageProps = {
  data: {
    page: {
      title: string
      slug: string
      excerpt: string
      body: string
    }
  }
  [key: string]: any
}

const accentColor = function(slug) {
  const strippedSlug = slug.slice(1);
  
  return accentColors[strippedSlug] || 'hsla(205, 79%, 66%, 1)';
}

const Page = ({ data: { page } }: PageProps) => (
  <Layout>
    <SEO title={page.title} description={page.excerpt} />
    <Heading as="h1" variant="styles.h1" style={{color: accentColor(page.slug)}}>
      {page.title}
    </Heading>
    <section sx={{ my: 5, variant: `layout.content` }}>
      <MDXRenderer>{page.body}</MDXRenderer>
    </section>
  </Layout>
)

export default Page
